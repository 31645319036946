
import {defineComponent} from "vue";
import SitesSelect       from "../banner-site/SitesSelect.vue";
import {useBannerGroup}  from "../../logic/banner/banner-group";

export default defineComponent({
  name      : "BannerGroupCreate",
  components: {
    SitesSelect: SitesSelect,
  },
  // @ts-ignore
  setup() {
    return useBannerGroup();
  },
  methods: {
    createGroupAndRedirect() {
      this.save().then((result) => {
        result && this.$router.push({name: 'banner-groups'})
      })
    }
  }
})
