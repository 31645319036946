<template>
  <form @submit.prevent class="uk-form-stacked">
    <div class="uk-margin">
      <label class="uk-form-label">Название</label>
      <input class="uk-input" type="text" v-model="group.name">
      <div class="" v-if="nameErrors">
        <span class="uk-text-danger" v-for="error in nameErrors">{{ error }}</span>
      </div>
    </div>


    <div class="uk-margin">
      <label class="uk-form-label">Алиас</label>
      <input class="uk-input" type="text" v-model="group.alias">
      <div class="" v-if="aliasErrors">
        <span class="uk-text-danger" v-for="error in aliasErrors">{{ error }}</span>
      </div>
    </div>

    <div class="uk-margin">
      <label class="uk-form-label">Сайт проекта</label>
      <SitesSelect v-model="group.sites"></SitesSelect>
      <div class="" v-if="sitesErrors">
        <span class="uk-text-danger" v-for="error in sitesErrors">{{ error }}</span>
      </div>
    </div>

    <button class="uk-button uk-button-primary" @click="createGroupAndRedirect">Сохранить</button>
  </form>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import SitesSelect       from "../banner-site/SitesSelect.vue";
import {useBannerGroup}  from "../../logic/banner/banner-group";

export default defineComponent({
  name      : "BannerGroupCreate",
  components: {
    SitesSelect: SitesSelect,
  },
  // @ts-ignore
  setup() {
    return useBannerGroup();
  },
  methods: {
    createGroupAndRedirect() {
      this.save().then((result) => {
        result && this.$router.push({name: 'banner-groups'})
      })
    }
  }
})
</script>
